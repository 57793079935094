
  import { Component, Watch } from 'vue-property-decorator'

  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixPrice, updateNestedObject } from '@/utils/general'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Evaluation } from '@/entities/loans'
  import { Form } from '@/entities/public/Resource/metadata'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import { EvaluationView } from '@/components/forms/view/EvaluationView'
  import { mapActions } from 'vuex'
  import { Person } from '@/entities/persons'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'

@Component({
  components: { GDatePicker, LinkedPerson, GRadioButton, FieldTitle, GFiles, LinkedAuto, BaseForm },
  methods: {
    fixPrice,
    ...mapActions('resources/form', ['getLoansDocument']),
  },
  computed: {},
})
  export default class CloseEvaluationForm extends EvaluationView {
  evaluation: Evaluation = plainToInstance(Evaluation, {})
  saleOrder = null
  getLoansDocument!: (id: number) => Promise<Record<string, any>>

  declare $refs: {
    form: HTMLFormElement
    fieldAcquirer: LinkedPerson
  };

  disabledAcquirer = false
  errorMessages = ''
  letterApprovalIsValid = false
  idFieldDocument = null
  documentId = null
  approvalProperties = {
    accept: '',
    multiple: false,
    fileTypeId: 0,
    name: '',
    label: '',
    required: false,
  }

  prohibitionProperties = {
    accept: '',
    multiple: false,
    fileTypeId: 0,
    name: '',
    label: '',
    required: false,
  }

  idProcess = null
  title = ''
  showDetail = false

  formData = {
    letterApproval: null,
    quotas: null,
    prohibition: null,
    acquirer: null,
    operationNumber: null,
    approvalDate: null,
  }

  fields = {
    operationNumber: {
      properties: {
        label: 'N° de operación',
        required: true,
      },
      rules: this.fieldRequired,
    },
    quotas: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    acquirer: {
      properties: {},
      items: [],
      rules: [],
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    await this.setMetadata()
    const { uid, id, model, title, metadataCollection } = this

    if ((!isNaN(uid) && (model === 'Evaluation' || model === '' || model === 'EvaluationClosing')) || !isNaN(id)) {
      const evaluationId = id || uid
      await this.getEvaluationInfo(evaluationId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(this.evaluation?.id))
    }

    this.setTheBackup()
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const newQuotas = 'newQuotas' in backup && backup.newQuotas

    if ('closeEvaluation' in backup && backup.closeEvaluation) {
      this.formData.acquirer = backup.closeEvaluation.acquirer

      if (!this?.formData?.prohibition?.length) {
        this.formData.prohibition = backup.closeEvaluation.prohibition
      }
      if (!this.formData?.letterApproval?.length) {
        this.formData.letterApproval = backup.closeEvaluation.letterApproval
      }
      if (!this?.formData?.quotas) {
        this.formData.quotas = backup.closeEvaluation.quotas
      }
    }

    if ('acquirer' in backup && backup.acquirer) {
      this.formData.acquirer = backup.acquirer
      this.fields.acquirer.items = [backup.acquirer]
    }

    if (newQuotas?.length) {
      this.formData.quotas = newQuotas[0]
    }
  }

  async getEvaluationInfo (id) {
    const evaluation = await this.fetchData({
      query: { name: 'fetch', model: 'Evaluation', params: { id } },
      force: true,
    })

    const financing = await this.fetchData({
      query: { name: 'fetch', model: 'Financing', params: { id: evaluation.financing.id } },
      force: true,
    })

    this.formData.operationNumber = evaluation.operationNumber
    this.formData.approvalDate = evaluation.approvalDate
    this.evaluation = evaluation
    this.evaluation.financing = financing
    this.fields.quotas.items = evaluation.offers

    const hasOffer = this.evaluation?.offers?.find(offer => offer.accepted)
    if (hasOffer) {
      this.formData.quotas = hasOffer
    }

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'evaluation' } },
    })

    this.idProcess = process[0].id

    const purchaseProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const document = await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'sale_purchase_contract' } }, { process: { id: { _eq: purchaseProcess[0].id } } }] },
    })

    this.idFieldDocument = document[0].fields.find(field => field.name === 'buyer').id
    await this.setAcquirer(id, financing.saleOrder, evaluation)
    await this.setDocuments(id)
    await this.setDetails()
  }

  async setAcquirer (id, saleOrder, evaluation) {
    const transfer = await this.getLoansDocument(id) as Record<string, any>

    const transferFetched = await this.fetchData({
      query: { name: 'find', model: 'TransferView' },
      filter: { stock_id: { _eq: saleOrder.deal.stock.id } },
    })

    if (transfer || transferFetched?.length || saleOrder?.document?.id) {
      this.documentId = transfer?.document_id || transferFetched[0]?.documentId || saleOrder?.document?.id
    }

    const evaluationInterveners = evaluation?.interveners?.map(item => plainToInstance(Person, item.person))
    const stockInterveners = transferFetched?.[0]?.document.interveners.filter(item => item.field.name === 'buyer').map(item => item.person)
    const persons = evaluationInterveners?.length ? evaluationInterveners : stockInterveners || saleOrder?.document?.acquirers
    this.disabledAcquirer = Boolean(persons?.length)
    this.formData.acquirer = persons
    this.fields.acquirer.items = persons?.length ? persons : [evaluation?.financing?.applicant]
  }

  async setDocuments (id) {
    const { idProcess } = this

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }] },
    })

    const approval = fileInfo.find(file => file.name === 'approval_letter')

    Object.assign(this.approvalProperties, {
      accept: approval.fileType.mimes,
      multiple: approval.multiple,
      fileTypeId: approval.fileType.id,
      name: approval.name,
      label: approval.description,
      required: approval.required,
      icon: approval.fileType.icon,
    })

    const prohibition = fileInfo.find(file => file.name === 'signed_prohibition')

    Object.assign(this.prohibitionProperties, {
      accept: prohibition.fileType.mimes,
      multiple: prohibition.multiple,
      fileTypeId: prohibition.fileType.id,
      name: prohibition.name,
      label: prohibition.description,
      required: prohibition.required,
      icon: prohibition.fileType.icon,
    })

    if (!id) return

    const documents = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
        ],
      },
      force: true,
    })

    if (documents.length) {
      this.formData.prohibition = [documents.find(file => file.parameter.name === 'signed_prohibition')].filter(Boolean)
      this.formData.letterApproval = [documents.find(file => file.parameter.name === 'approval_letter')].filter(Boolean)
      this.letterApprovalIsValid = this.formData.letterApproval?.[0]?.valid
    }

    await this.setStock()
  }

  async setStock () {
    const { evaluation } = this
    const auto = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { deals: { id: { _eq: evaluation.financing.saleOrder.deal.id } } },
      force: true,
    })

    this.evaluation.financing.saleOrder.deal.auto = auto[0]
  }

  async setMetadata () {
    const { metadata } = this.getForm('Evaluation', 'financier_evaluation_closing')
    const { form, fields } = metadata as Form

    this.fields.quotas.properties = fields.quotas.properties
    this.fields.acquirer.properties = fields.acquirer.properties
    this.metadataCollection = metadata
    this.title = form.title
  }

  async send () {
    const acquirer = this.$refs.fieldAcquirer

    if (!this.$refs.form.validate() || !acquirer.$refs.form.validate() || this.errorMessages.length) {
      return
    }
    this.loadingForm = true

    const {
      evaluation: { id },
    } = this

    if (id) {
      const financing = await this.fetchData({
        query: { name: 'find', model: 'Financing' },
        filter: { evaluations: { id: { _eq: id } } },
        force: true,
      })

      await this.updateEvaluation(this, financing[0])
    }

    await this.close()
    this.loadingForm = false
  }

  async setDetails () {
    const { metadataCollection, evaluation } = this

    evaluation.financing.applicant.laborData = await this.fetchData({
      query: { name: 'find', model: 'LaborData' },
      filter: {
        _and: [
          { person: { id: { _eq: evaluation.financing.applicant.id } } },
          { active: { _eq: true } }],
      },
      force: true,
    })

    this.metadata = {
      data: evaluation,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(evaluation.id)
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2) return null

    const { metadataCollection } = this

    if (!metadataCollection) return null
    const { fields } = metadataCollection as Form

    if (!fields?.acquirer) return
    const query = updateNestedObject(fields.acquirer.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    this.fields.acquirer.items = await this.findPersonSearch(query)
  }

  async goPerson () {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ ...formData })
    } else {
      backup.closeEvaluation = formData
      this.setBackup(backup)
    }

    const idPerson = this.formData.acquirer?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Evaluation', uid: null, id: idPerson } })
  }

  get bindAcquirer () {
    const { fields: { acquirer } } = this

    if (!acquirer) return {}

    return { ...acquirer.properties, items: acquirer.items }
  }

  @Watch('formData.acquirer', { immediate: true, deep: true })
  onAcquirerChange (val: Person[]) {
    if (!val) return

    let error = false
    val.forEach(person => {
      if (!person?.uid && !error) {
        error = true
        this.errorMessages = error ? `El adquiriente ${person.fullName} no tiene un RUT asociado` : ''
      }
    })

    if (!error) {
      this.errorMessages = ''
    }
  }

  get disabled () {
    const { evaluation, letterApprovalIsValid } = this

    return evaluation?.status?.isClosed && letterApprovalIsValid
  }

  async addQuota () {
    const { backup, formData, evaluation } = this
    if (!backup) {
      this.setBackup({ ...formData })
    } else {
      backup.closeEvaluation = formData
      this.setBackup(backup)
    }

    await this.$router.push({ name: 'generic-evaluation', params: { model: 'Evaluation', uid: evaluation.id.toString() } })
  }

  get evaluationIsClosed () {
    const { evaluation } = this

    return evaluation?.status?.isClosed
  }
  }
